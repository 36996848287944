/**
 * @file authority-manage模块接口
 */
import request from '@/utils/request';
import { requestByJsonType } from './common.js';

/** ============= 权限管理-专递课堂 ============= */

/**
 * @desc 获取专递课堂地址
 */
export const getCrossCourseAddress = () => {
  return request({
    url: '/crossCourseAddress/detail.do',
    method: 'POST',
  });
};

/**
 * @desc 结对组列表
 * @param { Number } pageSize 每页大小
 * @param { String } pageIndex 当前页
 */
export const getListPairGroup = (data) => {
  return request({
    url: '/base/listPairGroup.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 选择听讲学校列表
 * @param { String } schoolName 学校名称
 */
export const getListSchool = (data) => {
  return request({
    url: '/base/listSchool.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 选择主讲教室或者听讲教室列表
 * @param { Number } schoolId 学校id
 */
export const getListClassrom = (data) => {
  return request({
    url: '/base/listClassrom.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 移除结对组
 * @param { Number } pairGroupId 结对组id
 */
export const deletePairGroup = (data) => {
  return request({
    url: '/base/deletePairGroup.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存结对组信息
 * @param { Number } classRoomId 主讲教室id
 * @param { String } lectureRooms 听讲教室：[{"classRoomId":"700000000000001","schoolId":"700000000000001"},{"classRoomId":"700000000000002","schoolId":"700000000000001"},{"classRoomId":"800000000000001","schoolId":"800000000000001"}]
 * @param { Number } pairGroupId 结对组ID(不传时为新增结对组,有值为修改结对组)
 */
export const saveOrUpdatePairGroup = (data) => {
  return request({
    url: '/base/saveOrUpdatePairGroup.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存空中课堂配置
 * @param { Number } type 类型(0:没有专递课堂;1:靑鹿临时开课;2:接入ava专递课堂)
 * @param { String } remoteUrl 地址
 */
export const saveCrossCourseAddress = (data) => {
  return request({
    url: '/save/crossCourseAddress.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 查询学校的专递课堂课表列表
 * @param { String } startTime 开始日期，约定传当前周的周一，yyyy-MM-dd HH:mm:ss，不传的话， 查询所有数据。开始结束就是一周的时间范围，可以是周日到周一， 也可以是周一到周日，取决于前端显示的日期样式
 * @param { String } endTime 结束日期，约定传当前周的周日，yyyy-MM-dd HH:mm:ss，不传的话， 查询所有数据。开始结束就是一周的时间范围，可以是周日到周一， 也可以是周一到周日，取决于前端显示的日期样式
 */
export const getCourseListGroupByDate = (data) => {
  return request({
    url: '/cross/course/list/groupByDate/json.do',
    method: 'GET',
    data,
  });
};

/** ============= 业务管理-作业本绑定 ============= */

/**
 * @desc 作业本绑定信息列表
 * @param { Array } gradeIds 年级ids
 * @param { Array } lessonIds 学科ids
 * @param { Array } classIds 班级ids
 * @param { String } fuzzyName 作业名称模糊查询
 * @param { Number } schoolId 学校id（必传）
 * @param { Number } pageSize 页大小（必传）
 * @param { Number } pageIndex 页码（必传）
 */
export const selectPrintBookBindList = (data) => {
  return requestByJsonType({
    url: '/serv-teachplatform/userPrintBookBind/selectList',
    method: 'POST',
    data,
  });
};
/**
 * @desc 班级作业本绑定详情
 * @param { Number } schoolId 学校id（必传）
 * @param { Number } classId 班级id（必传）
 * @param { Number } noteBookId 作业本id（必传）
 * @param { String } fuzzyUserName 学生名称模糊查询
 */
export const getClassBookBindDetail = (data) => {
  return request({
    url: '/serv-teachplatform/userPrintBookBind/classBindDetail',
    method: 'GET',
    data,
  });
};
/**
 * @desc 学生作业本绑定
 * @param { Number } schoolId 学校id（必传）
 * @param { Number } noteBookId 作业本id（必传）
 * @param { String } bookCode 印刷本编码（必传）
 * @param { Number } classId 班级id（必传）
 * @param { Number } userPrintBookBindId 用户印刷本绑定id，有绑定修改传，没绑定时为空
 */
export const saveUserBookBind = (data) => {
  return request({
    url: '/serv-teachplatform/userPrintBookBind/bindPrintBook',
    method: 'POST',
    data,
  });
};

/** ============= 业务管理-个性化配置 ============= */

/**
 * @desc 个性化配置-配置列表
 * @param { Number } schoolId 学校id（必传）
 */
export const getCustomConfigSettings = (data) => {
  return request({
    url: '/serv-teachplatform/customConfig/settings/list',
    method: 'GET',
    data,
  });
};
/**
 * @desc 个性化配置-保存个性化配置
 * @param { Number } schoolId 学校id（必传）
 * @param { Number } userId 用户id（必传）
 * @param { String } reviewModeValue 批阅模式值：1.简约批阅 2：逐题批阅
 * @param { String } appraiseScoringRateScopeValue 评价得分率范围值，json字符串
 * @param { String } displayStudentRecognizeResultValue 纸笔同屏显示学生识别结果值
 * @param { String } visitMicroLectureLearningValue 访问微课学习值
 * @param { String } restoreDefault 是否恢复默认，true是，false否
 */
export const saveCustomConfigSettings = (data) => {
  return request({
    url: '/serv-teachplatform/customConfig/settings/save',
    method: 'POST',
    data,
  });
};
/**
 * @desc 个性化配置-获取个性化配置
 * @param { String } configName 配置名称，教学云平台名称固定传：TEACH_PLATFORM_NAME（必传）
 * @param { Number } schoolId 学校id
 */
export const getCustomConfigByKey = (data) => {
  return request({
    url: '/serv-teachplatform/customConfig/getByConfigKey',
    method: 'GET',
    data,
  });
};

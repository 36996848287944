const userRouter = {
  path: '/user',
  component: () => import('@/views/user/user.vue'),
  meta: {
    requiresTitle: true,
    title: '个人中心',
  },
};

export default userRouter;

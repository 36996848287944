/**
 * @file 公共方法
 */
import JSEncrypt from 'jsencrypt';
import { downloadByPost, downloadByGet, getLicense } from '@/api/common';
import { getToken, setToken } from '@/utils/cookies';

/**
 * @name getURLParams
 * @desc 获取链接参数
 * @param {String} url
 * @returns {Object} url参数
 */
export function getURLParams(url) {
  if (!url) url = window.location.hash;
  let params = {};
  url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (params[k] = v));
  return params;
}

/**
 * @name setURLParams
 * @desc param对象转换为url 参数
 * @param {Object} param
 * @returns {String} url参数
 */
export function setURLParams(param) {
  let url = '';
  for (let i in param) {
    if (param[i] || param[i] === false || param[i] === 0) {
      url += `${url.includes('?') ? '&' : '?'}${i}=${param[i]}`;
    }
  }
  return url;
}

/**
 * @name formatTime
 * @desc 时间戳转给yyyy-mm-dd
 * @param {String} timestamp 时间戳
 * @returns {} yyyy-mm-dd格式日期
 */
export function formatTime(timestamp) {
  const date = timestamp ? new Date(timestamp) : new Date();
  const year = formatNum(date.getFullYear());
  const month = formatNum(date.getMonth() + 1);
  const day = formatNum(date.getDate());
  return `${year}-${month}-${day}`;

  function formatNum(data) {
    return data < 10 ? `0${data}` : data;
  }
}

/**
 * @name rsaEncryption
 * @desc rsa 加密
 * @param {String} password 密码
 */

export const rsaEncryption = (password) => {
  if (!password) {
    return password;
  }

  const encryptor = new JSEncrypt();
  const publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCxOEhBGnY9xYds4b52gKkk0yjG32hSD/PRTP++5xGh42EWERvOcMqSwqK3vmxcmxC/SSolml3FZAF7auxdnnhtXwaXnjdhb1fo61h6ZnaRhgL7vjlVuCAnBy+8JT80T2nyPTqHnTzNjdGgSDsArIOH35RAiTUbUVrLnD4kBmOhwwIDAQAB';
  encryptor.setPublicKey(publicKey); // 设置公钥

  return encryptor.encrypt(password); // 对内容进行加密
};

/**
 * @name getHostUrl
 * @desc 获取hostUrl(用于数据库备份/离线升级相关的接口)
 */
export const getHostUrl = () => {
  var hostUrl = location.origin.replace(location.port, '');
  if (location.protocol == 'http:') {
    hostUrl = hostUrl + '13632/';
  } else if (location.protocol == 'https:') {
    hostUrl = hostUrl + ':13633/';
  }

  // 开发环境使用该基准路径
  if (process.env.NODE_ENV === 'development') {
    hostUrl = 'http://44.qljy.com:13632'; // 基准路径需要向后台要
  }
  return hostUrl;
};

/**
 * @name compareHostUrl
 * @desc 比较两个 hostUrl 是否完全相同
 */
export const compareHostUrl = (target, origin) => {
  if (typeof target !== 'string' || typeof origin !== 'string') {
    return undefined;
  }
  if (target.replace(/\/$/, '') === origin.replace(/\/$/, '')) {
    return true;
  }
  return false;
};

/**
 * @name checkNetworkStatus
 * @desc 检查网络状态（用于数据库备份/离线升级/安徽省平台数据同步的任务前置接口）
 */
export const checkNetworkStatus = (successCB, failCB) => {
  if (window.navigator.onLine) {
    getLicense({})
      .then(() => {
        successCB();
      })
      .catch(() => {
        failCB();
      });
  } else {
    failCB();
  }
};

/**
 * @name checkToken
 * @desc 检查token，当第三方免登录时，则将url中的token 赋到cookie中
 * @param {String} route 路由
 */
export const checkToken = (route) => {
  const hasToken = getToken();

  if (!hasToken) {
    const { query } = route;
    const { token } = query;
    if (token) {
      setToken(token);
    }
  }
};

/**
 * @name downloadFileByA
 * @desc 通过 a 标签下载文件
 * @param {String} url  下载链接
 * @param {String} name  文件名
 * @param {Object} option 配置
 */
export function downloadFileByA(url, name = '', option = {}, callback) {
  let eleLink = document.createElement('a');
  eleLink.href = url;
  if (name) {
    eleLink.download = name;
  }
  eleLink.style.display = 'none';
  Object.assign(option, eleLink);
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
  callback && callback();
}

/**
 * @name downloadFileByPost
 * @desc POST下载文件
 * @param {String} url  下载链接
 * @param {String} data  传参
 * 参考智慧作业
 */
export function downloadFileByPost(url, data = {}, cancelToken) {
  return new Promise((resolve, reject) => {
    downloadByPost(url, data, cancelToken)
      .then((response) => {
        const res = response.data;
        if (res instanceof Blob) {
          if (res.type === 'application/json') {
            const fileReader = new FileReader();
            fileReader.readAsText(res, 'utf-8');
            fileReader.onerror = (e) => {
              console.error(e);
              reject(`文件读取错误: ${fileReader.error}`);
              fileReader.abort();
            };
            fileReader.onload = (e) => {
              const result = JSON.parse(e.target.result);
              if (result.status !== 0 && result.msg) {
                reject(result.msg);
              }
            };
          } else {
            let name = response.headers['content-disposition']?.replace('attachment;filename=', '') || '';
            name = decodeURI(name);

            // 本地环境，name为空，手动写个名字
            if (process.env.NODE_ENV === 'development' && !name) {
              name = '下载.xlsx';
            }

            // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
            if ('msSaveOrOpenBlob' in navigator) {
              navigator.msSaveOrOpenBlob(res, name);
              return;
            }
            const url = URL.createObjectURL(res);
            downloadFileByA(url, name);
            URL.revokeObjectURL(url);
            resolve();
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @name downloadFileByGet
 * @desc GET下载文件
 * @param {String} url  下载链接
 * @param {String} data  传参
 */
export function downloadFileByGet(url, data = {}, cancelToken) {
  return new Promise((resolve, reject) => {
    downloadByGet(url, data, cancelToken)
      .then((response) => {
        const res = response.data;
        if (res instanceof Blob) {
          if (res.type === 'application/json') {
            const fileReader = new FileReader();
            fileReader.readAsText(res, 'utf-8');
            fileReader.onerror = (e) => {
              console.error(e);
              reject(`文件读取错误: ${fileReader.error}`);
              fileReader.abort();
            };
            fileReader.onload = (e) => {
              const result = JSON.parse(e.target.result);
              if (result.status !== 0 && result.msg) {
                reject(result.msg);
              }
            };
          } else {
            let name = response.headers['content-disposition']?.replace('attachment;filename=', '') || '';
            name = decodeURI(name);

            // 本地环境，name为空，手动写个名字
            if (process.env.NODE_ENV === 'development' && !name) {
              name = '下载.xlsx';
            }

            // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
            if ('msSaveOrOpenBlob' in navigator) {
              navigator.msSaveOrOpenBlob(res, name);
              return;
            }
            const url = URL.createObjectURL(res);
            downloadFileByA(url, name);
            URL.revokeObjectURL(url);
            resolve();
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @name checkTextOverflowing
 * @desc 判断文本是否超过限制长度(text-overflow: ellipsis)
 * @param dom 计算节点
 * @return {boolean} 是否超过
 */
export const checkTextOverflowing = (dom) => {
  if (!dom) return;
  // use range width instead of scrollWidth to determine whether the text is overflowing
  // to address a potential FireFox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1074543#c3
  const range = document.createRange();
  range.setStart(dom, 0);
  range.setEnd(dom, dom.childNodes.length);
  const rangeWidth = range.getBoundingClientRect().width;
  const padding = (parseInt(dom.style.paddingLeft, 10) || 0) + (parseInt(dom.style.paddingRight, 10) || 0);

  return rangeWidth + padding > dom.offsetWidth || dom.scrollWidth > dom.offsetWidth;
};

/**
 * @name 复制文本
 * @desc 将某段文本复制到粘贴板
 */
export const copyText = (text) => {
  if (!text) return false;
  if (!document.execCommand('copy')) return false;

  let inputContainer = document.createElement('input');
  inputContainer.style = `
    position: fixed;
    top: -100px;
    left: -100px;
    opacity: 0;
  `;
  inputContainer.value = text;
  document.body.appendChild(inputContainer);
  inputContainer.select();
  // 将选中内容复制到粘贴板
  document.execCommand('copy');
  document.body.removeChild(inputContainer);
  return true;
};

/**
 * 格式化时间
 *
 * console.log(formatDateTime(date, 'yyyy-MM-dd HH:mm:ss'));    // 2023-02-16 08:25:05
 * console.log(formatDateTime(date, 'yyyy年MM月dd日 HH:mm:ss')); // 2023年02月16日 08:25:05
 * console.log(formatDateTime(date, 'yyyy-MM-dd HH:mm:ss S')); // 2023-02-16 08:25:05 950
 * console.log(formatDateTime(date, 'yyyy-MM-dd hh:mm:ss A')); // 2023-02-16 08:25:05 上午
 * */
export const formatDateTime = (_date, format) => {
  if (!_date) return;

  const date = new Date(_date);

  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    a: date.getHours() < 12 ? '上午' : '下午', // 上午/下午
    A: date.getHours() < 12 ? 'AM' : 'PM', // AM/PM
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
};

/**
 * @desc 获取访问的地址
 * @param {string} path 路由地址
 * @param {string} serverUrl 访问host地址
 */
export const getHostUrlPath = (path, serverUrl) => {
  const { origin, pathname } = location;
  let hostUrl = serverUrl !== undefined ? serverUrl : origin;
  return `${hostUrl}${pathname}#${path}`;
};

/**
 * 数字相加 解决小数精度问题
 * @param {...number} args
 */
export const sum = (...args) => {
  return (args.reduce((c, num) => (c += parseFloat(num || 0)), 0).toFixed(3) * 1000) / 1000;
};

/**
 * 文件地址处理
 * @param {string} url 文件地址
 */
export const getFileUrl = (url) => {
  const BASEURL = process.env.NODE_ENV === 'development' ? window.$ctx : window.location.origin;
  return new URL(url, BASEURL).href;
};

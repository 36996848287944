import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Router Modules */
import backStageRouter from './modules/back-stage.js';
import dataPrepareRouter from './modules/data-prepare.js';
import userRouter from './modules/user.js';

/**
 * constantRoutes（无权限路由）
 * a base page that does not have permission requirements
 */
export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ `@/views/login-${process.env.VUE_APP_PLATFORM}/index.vue`),
    meta: {
      requiresTitle: true,
    },
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
    meta: {
      requiresTitle: true,
    },
  },
  {
    path: '/sync-redirect',
    component: () => import('@/views/sync-redirect/index.vue'),
    meta: {
      requiresTitle: true,
    },
  },
  {
    path: '/sync-wechat',
    component: () => import('@/views/sync-wechat/index.vue'),
    meta: {
      requiresTitle: true,
    },
  },
  userRouter,
];

/**
 * asyncRoutes（权限路由）
 * the routes that need to be dynamically loaded based on user permission
 */
export const asyncRoutes = [
  /** when your routing map is too long, you can split it into small modules **/
  // 分开多个是因为它们有不同的侧边栏和头部
  backStageRouter,
  dataPrepareRouter,

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/login',
    meta: {
      hidden: true,
    },
  },
];

const createRouter = () =>
  new Router({
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

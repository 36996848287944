import { getLicense, getCloudLicense } from '@/api/common';

const state = {
  // 版本信息
  server: {
    versionName: '--',
    versionCode: '',
  },
  schoolInfo: {},
  // 打开微信登录
  showWechatLogin: false,
  // 是否勾选用户协议
  hasRead: true,
};

const mutations = {
  setServerVersion(state, data) {
    state.server = data;
  },
  setSchoolInfo(state, name) {
    state.schoolInfo = name;
  },
  setShowWechatLogin(state, bool) {
    state.showWechatLogin = bool;
    sessionStorage.setItem('showWechat', bool);
  },
  setHasRead(state, bool) {
    state.hasRead = bool;
  },
};

const actions = {
  /**
   * @desc 获取版本信息
   * @param {string} platform school-校层 cloud-云层
   */
  async fetchServerVersion({ commit }, platform) {
    const fetchApi = platform === 'school' ? getLicense : getCloudLicense;
    const { result } = await fetchApi();
    commit('setServerVersion', result || { versionName: '--', versionCode: '' });
  },

  updateSchoolInfo({ commit }) {
    // 读取缓存
    let cacheSchool = localStorage.getItem('zhkt_school');
    if (cacheSchool && JSON.parse(cacheSchool)) {
      commit('setSchoolInfo', JSON.parse(cacheSchool));
    }
  },

  updateShowWechatLogin({ commit }) {
    let showWechat = sessionStorage.getItem('showWechat');
    if (showWechat && JSON.parse(showWechat)) {
      commit('setShowWechatLogin', JSON.parse(showWechat));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

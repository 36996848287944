/**
 * @file 第三方平台处理方法
 */

/**
 * @name syncLogout
 * @desc 第三方平台登出
 */
export const syncLogout = () => {
  const syncToken = localStorage.getItem('syncToken');
  const thirdProjectCode = localStorage.getItem('thirdProjectCode');
  localStorage.removeItem('syncToken');
  localStorage.removeItem('thirdProjectCode');

  // 登出宁夏、5G平台（拓维相关平台要求：需要浏览器定位到登出地址才能登出，ajax方式不可行）
  if (thirdProjectCode === 'ningxia') {
    window.location.href = `${window.$ctx}/OAuth2/ningxia/web/logout.do?token=${syncToken}`;
  } else if (thirdProjectCode === 'gdedu') {
    window.location.href = `${window.$ctx}//OAuth2/gdae/web/logout.do?token=${syncToken}`;
  }
};

/**
 * @file 权限与路由相关的公共常量和方法
 */
import store from '@/store';
import router from '@/router';

// 系统对应角色id
export const schoolAdminRoleId = '14'; // 校层管理员
export const superAdminRoleId = '16'; // 超级管理员
export const avaSettingAdminRoleId = '17'; // 全连接配置管理员
export const teachingAdminRoleId = '32'; // 教辅管理员

// 所有管理员角色id
export const adminRoleIds = [schoolAdminRoleId, superAdminRoleId];

// 权限列表
export const allPermissions = {
  // 校层超级管理员
  // 'all-connected-platform-access-config': {
  //   type: 'route',
  //   id: 147,
  //   title: '全连接平台接入配置',
  // },
  // 'third-party-school-account-sync': {
  //   type: 'route',
  //   id: 158,
  //   title: '第三方学校账号同步',
  // },
  // 'school-data-push-config': {
  //   type: 'route',
  //   id: 149,
  //   title: '学校数据推送配置',
  // },
  // 'Anhui-supervision-platform-access-config': {
  //   type: 'route',
  //   id: 155,
  //   title: '安徽监管平台接入配置',
  // },

  // 校层管理员
  'basic-data': {
    type: 'route',
    id: 150,
    title: '基础数据',
    roleId: schoolAdminRoleId,
  },
  'equipment-manage': {
    type: 'route',
    id: 151,
    title: '设备管理',
    roleId: schoolAdminRoleId,
  },
  'authority-manage': {
    type: 'route',
    id: 152,
    title: '业务管理',
    roleId: schoolAdminRoleId,
  },

  // 云层超级管理员
  // 'register-education-bureau': {
  //   type: 'route',
  //   id: 118,
  //   title: '注册教育局',
  // },
  // 'register-school': {
  //   type: 'route',
  //   id: 119,
  //   title: '注册学校',
  // },
  // 'remote-upgrade': {
  //   type: 'route',
  //   id: 142,
  //   title: '远程升级',
  // },
  // 'third-party-manage': {
  //   type: 'route',
  //   id: 144,
  //   title: '第三方管理',
  // },
};

/**
 * @name getRouteByPermission
 * @desc 获取用户信息，用户权限来设置动态路由
 */
export const getRouteByPermission = async () => {
  const permissions = await store.dispatch('user/getUserPermission');

  // generate accessible routes map based on roles
  const accessRoutes = await store.dispatch('route/generateRoutes', permissions);

  // dynamically add accessible routes
  accessRoutes.map((route) => {
    router.addRoute(route);
  });
};

export default allPermissions;

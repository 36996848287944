import qs from 'qs';

/**
 * @file basic模块接口
 */
import request from '@/utils/request';
import { requestByJsonType } from './common.js';

/**
 * @desc 查看当前学校详情
 */
export const getSchoolShow = (data) => {
  return request({
    url: '/json/schoolShow.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 查看当前学校详情
 */
export const getSchoolInfo = () => {
  return request({
    url: '/serv-manager/org/school/detail',
    method: 'get',
  });
};

/**
 * @desc 查看当前学校详情
 * @param { Number } schoolId 学校id
 * @param { String } schoolName 学校名称
 * @param { Number } schoolType 学校类类型id
 * @param { String } schoolShortName 学校代码
 */
export const saveSchool = (data) => {
  return request({
    url: '/schoolSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 学科列表
 *
 * @param {} data.status   - 0启用 1禁用 不传查所有
 * @param {} data.periodId - 学段id
 */
export const getLessonList = (data) => {
  return request({
    url: '/serv-manager/basicSchLesson/list',
    data,
    method: 'GET',
  });
};

/**
 * @desc 保存学科信息
 */
export const saveLesson = (data, isEdit = false) => {
  return request({
    url: isEdit ? '/chonggou/lessonEditSave.do' : '/chonggou/lessonSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 新增学科
 * @param { Number } data.periodId     - 学段id
 * @param { String } data.lessonName   - 学科别名
 */
export const addSubject = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchLesson/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑学科
 * @param { Number } data.lessonId    - 学科id
 * @param { String } data.lessonName  - 学科别名
 */
export const editSubject = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchLesson/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除学科
 * @param { Number } data.lessonId    - 学科id
 */
export const deleteSubject = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchLesson/delete',
    method: 'POST',
    data,
  });
};

/**
 * @desc 启用/禁用 学科
 * @param { Number } data.lessonId     - 学科id
 * @param { String } data.status       - 切换的状态（0-启用 1-禁用）
 */
export const setSubjectStatus = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchLesson/statusSwitch',
    method: 'POST',
    data,
  });
};
/**
 * @desc 编辑年级信息，年级保存
 * @param { String } gradeId 年级id
 * @param { String } gradeUserId1 级长用户id
 */
export const saveGrade = (data) => {
  return request({
    url: '/gradeSave.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-班级 */
/**
 * @desc 班级列表 表格数据
 *
 * @param { String } data.className    - 班级名称（搜索）
 * @param { Array } data.gradeIds      - 年级id集合
 * @param { String } data.order        - 年级排序，asc升序 desc降序
 * @param { String } data.numsOrder    - 人数排序，asc升序 desc降序
 * @param { String } data.schoolYearId - 学年id
 * @param { Array } data.classIds      - 班级id集合
 * @param { String } data.schoolYearId - 升年级 - 数据准备 - 任教，带上升年级的id
 * @param { String } data.status       - 0-启用 1-禁用
 *
 * @param { Number } data.pageIndex  - 当前页
 * @param { Number } data.pageSize   - 每一页显示多少条记录
 *
 */
export const getClassList = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchClass/selectList',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询升学年 班级异常数据
 *
 * @param { String } data.schoolYearId - 学年id
 *
 */
export const getErrorClassList = (data) => {
  return request({
    url: '/serv-manager/basicSchClass/selectExcData',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增班级界面数据初始化
 * @param { String } gradeId 当前年级id
 */
export const getClassAdd = (data) => {
  return request({
    url: '/json/classAdd.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 查询班级下的所有老师和学生
 * @param { Number } data.classId - 班级ID
 */
export const getTeacherStudentList = (data) => {
  return request({
    url: '/serv-manager/basicClassRStu/selectAllPerson',
    method: 'GET',
    data,
  });
};

/**
 * @desc 班级学生列表
 * @param { String } data.searchKey      - 关键字
 * @param { Number } data.classId        - 班级id
 * @param { Number } data.schoolId       - 学校id
 * @param { Number } data.pageIndex      -
 * @param { String } data.pageSize       -
 */
export const getClassUserList = (data) => {
  return request({
    url: '/serv-manager/basicClassRStu/selectList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增班级名单
 * @param { Number } classId 班级id
 * @param { String } userId 学生id
 */
export const addNewClassUser = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRStu/add',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量转班
 * @param { Number } data.oldClassId - 原班级id
 * @param { Number } data.newClassId - 新班级id
 * @param { Array }  data.stuIds     - 学生id数组
 */
export const changeClassUser = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRStu/changeClass',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除班级名单
 * @param { String }  data.classId - 班级id
 * @param { String }  data.stuIds  - 学生id数组
 */
export const deleteClassUser = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRStu/deleteBatch',
    method: 'POST',
    data,
  });
};

/**
 * @desc 查询班级列表
 * @param { Number }  data.gradeId - 年级id
 * @param { Array }  data.stuIds  - 当前学生id数组
 */
export const getClassUserListData = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRStu/selectClassList',
    method: 'POST',
    data,
  });
};

/**
 * @desc 新增班级
 * @param { String } className 班级名称
 * @param { Number } teacherId 班主任用户id
 * @param { Number } gradeId 年级id
 */
export const addClass = (data) => {
  return request({
    url: '/chonggou/classAddSave.do',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑班级名称
 * @param { String } data.className - 班级名称
 * @param { String } data.classId   - 班级id
 */
export const updateClass = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchClass/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除班级
 * @param { String } data.classId - 班级id
 */
export const deleteClass = (data) => {
  return requestByJsonType({
    url: `/serv-manager/basicSchClass/delete?classId=${data.classId}`,
    method: 'POST',
  });
};

/**
 * @desc 查询非本班的学生
 * @param { Number } data.classId - 班级id
 * @param { String } data.stuName - 学生名
 */
export const selectNotInClassStudent = (data) => {
  return request({
    url: '/serv-manager/basicClassRStu/selectNotInClassStudent',
    method: 'GET',
    data,
  });
};

/**
 * @desc 导出姓名贴
 * @param { String } schoolId - 学校id
 * @param { Array } classIds - 班级id数组
 */
export const exportClassNameStick = (data) => {
  return requestByJsonType({
    url: '/serv-manager/biz/classnamelistPage/exportClassNameStick',
    method: 'GET',
    data,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

/**
 * @desc 导出姓名贴-状态查询
 * @param { String } homeworkId - 作业id
 */
export const getDotProduceDownloadStatus = (data) => {
  return requestByJsonType({
    url: '/serv-teachplatform/hw/downloadPrint/getDotProduceDownloadStatus',
    method: 'GET',
    data,
  });
};

/** 基础数据-任教 */
/**
 * @desc 初始化数据（包含任教信息和年级信息）
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每一页显示的记录数量
 * @param { String } gradeId 年级id，有选择年级就传入年级id， 没有选择年级就不需要传入
 * @param { String } classId 班级id，有选择班级就传入年级id， 没有选择班级就不需要传入
 */
export const getClassLessonListNew = (data) => {
  return request({
    url: '/classLessonListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 是否已关联任教
 * @param { String } data.lessonId 当前页码
 */
export const getIsRelateTeaching = (data) => {
  return request({
    url: '/serv-manager/basicSchLesson/isRelateTeaching',
    method: 'GET',
    data,
  });
};

/**
 * @desc 任教列表
 *
 * @param {} data.commonFilter  - 教师账号、姓名、手机号、工号筛选
 * @param {} data.gradeIdList   - 年级id列表
 * @param {} data.classIdList   - 班级id列表
 * @param {} data.subjectIdList - 学科id列表
 * @param {} data.gradeOrder    - 年级排序 0-升序 1-降序 默认升序
 * @param {} data.classOrder    - 班级排序 0-升序 1-降序 默认升序
 * @param {} data.subjectOrder  - 学科排序 0-升序 1-降序 默认升序
 *
 * @param {} data.schoolYearId  - 学年id,不传默认当前学年
 * @param {} data.showAddtional - 默认false,显示额外信息（没有任教的班级-学科、老师）
 * @param {} data.upgradePlanId - 升年级计划id (数据准备页面传)
 *
 * @param {} data.pageIndex     -
 * @param {} data.pageSize      -
 * */
export const getTeachList = (data) => {
  return request({
    url: '/serv-manager/basicClassRTeacher/selectPageList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 任教列表分页查询（只包含任教信息）
 * @param { String } pageSize 每页大小
 * @param { String } pageIndex 当前页
 * @param { String } gradeId 年级id，有选择年级就传入年级id， 没有选择年级就不需要传入
 * @param { Number } classId 班级id，有选择班级就传入年级id， 没有选择班级就不需要传入
 */
export const getClassLessonList = (data) => {
  return request({
    url: '/json/classLessonList.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 删除任教信息
 * @param { Array } data.idList 任教关系id列表
 */
export const delClassLesson = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRTeacher/batchDetele',
    method: 'POST',
    data,
  });
};
/**
 * @desc 新增任教信息界面数据初始化
 */
export const getClassLessonAdd = (data) => {
  return request({
    url: '/json/classLessonAdd.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增任教
 *
 * @param { Number } data.classId   - 班级id，新增的时候必填， 编辑的时候不需要传
 * @param { Number } data.lessonId - 学科id ，新增的时候必填， 编辑的时候不需要传
 * @param { String } data.userId    - 教师id
 */
export const addTeach = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRTeacher/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑任教
 *
 * @param {Number} data.id        - 任教关系id
 * @param {Number} data.teacherId - 教师id
 * */
export const editTeach = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassRTeacher/edit',
    method: 'POST',
    data,
  });
};

/** 基础数据-家长 */
/**
 * @desc 家长列表初始化数据（包含年级信息和家长信息
 * @param { String } pageIndex 页码
 * @param { String } pageSize 每页显示的记录
 */
export const getParentListNew = (data) => {
  return request({
    url: '/parentListNew.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 家长列表分页查询
 * @param { Number } pageSize 每页大小
 * @param { Boolean } pageIndex 当前页
 * @param { Number } gradeId 年级id,不传默认查询所有
 * @param { String } classId 班级id,不传默认查询所有
 * @param { String } parentName 家长姓名（搜索框内容）
 */
export const getParentList = (data) => {
  return request({
    url: '/json/parentList.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除家长
 * @param { String } parentId 家长id
 */
export const delParent = (data) => {
  return request({
    url: '/parentDelete.do',
    method: 'POST',
    data,
  });
};

/** 基础数据-教室 */
/**
 * @desc 教室列表界面数据初始化
 * @param { String } data.classroomName - 教室名称
 * @param { Number } data.pageIndex     -
 * @param { Number } data.pageSize      -
 */
export const getClassroomListNew = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassroom/selectPageList',
    method: 'POST',
    data,
  });
};

/**
 * @desc 教室分页查询
 * @param { Number } pageSize 每页大小
 * @param { Number } pageIndex 当前页
 * @param { Number } gradeId 年级id，选择的年级
 * @param { Number } classId 班级id，选择的班级
 */
export const getClassroomList = (data) => {
  return request({
    url: '/classrom/page/json.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 新增或编辑教室
 * @param { Number } data.classroomId    - 教室id
 * @param { String } data.classroomName  - 教室名称
 */
export const saveClassroom = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicClassroom/saveOrUpdate',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除课室
 * @param { String } data.classroomId - 教室id
 */
export const delClassroom = ({ classroomId }) => {
  return requestByJsonType({
    url: `/serv-manager/basicClassroom/delete?classroomId=${classroomId}`,
    method: 'POST',
  });
};

/** 基础数据-教师 */

/**
 * @desc 教师-分页查询
 *
 * @param {Number} data.commonFilter  - 教师账号、姓名、手机号、教职工号的模糊匹配字段
 * @param {Number} data.pageIndex     - 每页显示多少条记录
 * @param {String} data.pageSize      - 年级id
 * @param {String} data.upgradePlanId - 升年级计划ID (数据准备页面传)
 */
export const getTeacherTable = (data) => {
  return request({
    url: '/serv-manager/teacher/selectPageList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 教师-新增
 *
 * @param { String } data.name     - 姓名
 * @param { String } data.mobileNo - 手机号
 * @param { String } data.idNumber - 教职工号
 * @param { Number } data.sex      - 性别 1-男 2-女
 * @param { String } data.password - 密码（加密后）
 */
export const addTeacher = (data) => {
  return requestByJsonType({
    url: '/serv-manager/teacher/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 教师-编辑
 *
 * @param { String } data.userId   - 编辑的记录id
 * @param { String } data.name     - 姓名
 * @param { String } data.mobileNo - 手机号
 * @param { String } data.idNumber - 教职工号
 * @param { Number } data.sex      - 性别 1-男 2-女
 */
export const editTeacher = (data) => {
  return requestByJsonType({
    url: '/serv-manager/teacher/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 教师-批量删除（单个删除直接调该接口）
 *
 * @param { Array } data.userIdList - 批量删除的id列表
 */
export const deleteTeachers = (data) => {
  return requestByJsonType({
    url: '/serv-manager/teacher/batchDetele',
    method: 'POST',
    data,
  });
};

/**
 * @desc 教师-批量修改密码
 *
 * @param { Array }  data.userIdList   - 批量修改密码的id列表
 * @param { Number } data.passwordType - 密码类型 0-默认密码 1-新密码
 * @param { String } data.password     - 密码（加密后）
 */
export const editTeacherPassword = (data) => {
  return requestByJsonType({
    url: '/serv-manager/teacher/batchModifyPwd',
    method: 'POST',
    data,
  });
};

/** 基础数据-学生 */

/**
 * @desc 学生列表分页查询
 * @param {String} data.commonFilter          - 教师账号、姓名、手机号、教职工号的模糊匹配字段
 * @param {Number} data.pageIndex             -
 * @param {Number} data.pageSize              -
 * @param {String} data.classIdList           - 班级id列表
 */
export const getStudentList = (data) => {
  return request({
    url: '/serv-manager/student/selectPageList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 学段年级列表
 */
export const getSectionGradeList = () => {
  return request({
    url: '/serv-manager/basicSchGrade/list',
    method: 'GET',
  });
};

/**
 * @desc 年级列表查询
 *
 * @param {Number} data.status   - 0-启用 1-禁用
 * @param {Number} data.periodId - 学段id
 */
export const getGradeList = (data) => {
  return request({
    url: '/serv-manager/basicSchGrade/selectList',
    data,
    method: 'GET',
  });
};

/**
 * @desc 新增年级
 *
 * @param {String} data.gradeName - 年级别名
 * @param {Number} data.periodId  - 学段id
 * @param {Number} data.seq       - 升学年序号
 */
export const addGrade = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchGrade/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑年级
 *
 * @param {Number} data.gradeId   - 年级id
 * @param {String} data.gradeName - 年级别名
 * @param {Number} data.seq       - 升学年序号
 */
export const editGrade = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchGrade/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 年级删除
 *
 * @param {Number} data.gradeId   - 年级id
 */
export const deleteGrade = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchGrade/delete',
    method: 'POST',
    data,
  });
};

/**
 * @desc 启动/禁用 年级
 *
 * @param {Number} data.gradeId   - 年级id
 * @param {Number} data.status    - 切换的状态（0-启用 1-禁用）
 */
export const setGradeStatus = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchGrade/statusSwitch',
    method: 'POST',
    data,
  });
};

/**
 * @desc 是否已关联班级
 *
 * @param {Number} data.gradeId   - 年级id
 */
export const getGradeIsUsed = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchGrade/isRelateClass',
    method: 'GET',
    data,
  });
};

/**
 * @desc 选择年级后刷新班级数据
 * @param { String } gradeId 年级id
 */
export const getClassListData = (data) => {
  return request({
    url: '/json/classListData.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 学生-新增
 * @param {String} data.name     - 姓名
 * @param {String} data.idNumber - 教职工号
 * @param {String} data.sex      - 性别 1-男 2-女
 * @param {String} data.password - 密码（rsa加密后）
 */
export const addStudent = (data) => {
  return requestByJsonType({
    url: '/serv-manager/student/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 学生-编辑
 * @param {number} data.userId    - 编辑的记录id
 * @param {string} data.name      - 姓名
 * @param {string} data.idNumber  - 教职工号
 * @param {number} data.sex       - 性别：1-男 2-女
 */
export const editStudent = (data) => {
  return requestByJsonType({
    url: '/serv-manager/student/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 学生-批量修改密码
 * @param {Array} data.userIdList    - 批量修改密码的id列表
 * @param {Number} data.passwordType - 密码类型 0-默认密码 1-新密码
 * @param {String} data.password     - 密码（加密后）
 */
export const saveStudentPassword = (data) => {
  return requestByJsonType({
    url: '/serv-manager/student/batchModifyPwd',
    method: 'POST',
    data,
  });
};

/**
 * @desc 批量删除学生信息
 * @param { Array } data.userIdList - 批量删除的id列表
 */
export const batchDeleteStudent = (data) => {
  return requestByJsonType({
    url: '/serv-manager/student/batchDetele',
    method: 'POST',
    data,
  });
};

/** 基础数据-升年级 */
/**
 * @desc 显示升年级配置界面数据初始化
 */
export const getUpgradeSetting = (data) => {
  return request({
    url: '/grade/json/upgrade/setting/edit/view.do',
    method: 'GET',
    data,
  });
};

/** 数据库备份 */

/**
 * @desc 启动数据库备份
 * @param {String} host hostUrl
 */
export const startBackup = (hostUrl, data) => {
  return request({
    baseURL: hostUrl,
    url: '/backupMysql',
    method: 'GET',
    data,
  });
};
/**
 * @desc 查询数据库备份任务状态
 */
export const getBackupTaskStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/status',
    method: 'GET',
  });
};

/** 离线升级 */

/**
 * @desc 查询升级状态
 */
export const checkUpgradeResult = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/tipOnce',
    method: 'GET',
  });
};

/**
 * @desc 查询当前版本信息
 */
export const getCurrentVersion = () => {
  return request({
    url: '/webapp/m/getCurrentVersion.do',
    method: 'GET',
  });
};
/**
 * @desc go项目-离线升级-查询最新备份时间
 */
export const getLastBackupTime = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/lastBackupTime',
    method: 'GET',
  });
};
/**
 * @desc 查询数据库备份任务状态
 */
export const getUpgradeTaskStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/status',
    method: 'GET',
  });
};
/**
 * @desc 获取重启状态
 */
export const getRestartStatus = () => {
  return request({
    url: '/client/init/school/status.do',
    method: 'GET',
    ignoreTimeoutError: true,
  });
};

/**
 * @desc 通知服务器设置上传安装包的状态
 */
export const setUpgradeStatus = (hostUrl) => {
  return request({
    baseURL: hostUrl,
    url: '/setUpgradeStatus',
    method: 'GET',
  });
};

/**
 * @desc 启动离线升级
 * @param {File} file 升级包文件
 * @param {String} host hostUrl
 * @param {Number} backup 1. 若升级失败使用已备份的数据库进行回滚 0. 否
 */
export const uploadUpgradeFile = (hostUrl, data) => {
  return request({
    baseURL: hostUrl,
    url: '/upgrade',
    method: 'POST',
    isUpload: true,
    data,
  });
};

/** 安徽省平台数据同步 */

/**
 * @desc 任务ID查询任务状态
 * @param { String } schoolKey 学校key
 */
export const getNewestTaskBySchoolKey = (data) => {
  return request({
    url: '/anhuisync/getNewestTaskBySchoolKey.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 安徽用户ID查询最近的一次任务状态
 * @param {String} userId 安徽用户ID/第三方token
 */
export const getNewestTaskByAnhuiUserId = (data) => {
  return request({
    url: '/anhuisync/getNewestTaskByAnhuiUserId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 学校key查询最近的一次任务状态
 * @param { Number } taskId 任务ID
 */
export const getTaskByTaskId = (data) => {
  return request({
    url: '/anhuisync/getTaskByTaskId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 学校key启动数据同步
 * @param { String } schoolKey 学校key
 */
export const startSync = (data) => {
  return request({
    url: '/anhuisync/startSyncBySchoolKey.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 安徽用户ID启动数据同步
 * @param { String } userId 安徽第三方登录返回的用户ID
 */
export const startSyncByAnhuiUserId = (data) => {
  return request({
    url: '/anhuisync/startSyncByAnhuiUserId.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 同步全连接基础数据
 */
export const avaSyncSchool = (data) => {
  return request({
    url: '/ava/syncSchool.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 学年列表
 */
export const getYearList = () => {
  return request({
    url: '/serv-manager/basicSchYear/list',
    method: 'get',
  });
};

/**
 * @desc 新增学期
 *
 * @param {Number} data.schoolYearId - 学年id
 * @param {String} data.termName     - 学期名称
 * @param {String} data.startTime    - 学期开始时间
 * @param {String} data.endTime      - 学期结束时间
 */
export const addSemester = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchTerm/save',
    method: 'POST',
    data,
  });
};

/**
 * @desc 编辑学期
 *
 * @param {Number} data.termId       - 学年id
 * @param {String} data.termName     - 学期名称
 * @param {String} data.startTime    - 学期开始时间
 * @param {String} data.endTime      - 学期结束时间
 */
export const editSemester = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchTerm/edit',
    method: 'POST',
    data,
  });
};

/**
 * @desc 删除学期
 *
 * @param {Number} data.termId       - 学年id
 */
export const deleteSemester = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchTerm/delete',
    method: 'POST',
    data,
  });
};

/**
 * @desc 获取当前学年
 */
export const getCurrentYear = () => {
  return request({
    url: '/serv-manager/basicSchYear/getCurrentYear',
    method: 'GET',
  });
};

/**
 * @desc 编辑学年
 *
 * @param {number} data.schoolYearId   - 学年id
 * @param {String} data.schoolYearName - 学年名称
 */
export const editYear = (data) => {
  return requestByJsonType({
    url: '/serv-manager/basicSchYear/edit',
    method: 'POST',
    data,
  });
};

/** 基础数据-升年级 */
/**
 * @desc 分页查询升年级计划
 *
 * @param {number} size  每页大小
 * @param {number} current 当前页
 */
export const getUpgradeList = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/listPage',
    method: 'GET',
    data,
  });
};

/**
 * @desc 删除升年级计划
 *
 * @param {number} id  计划id
 */
export const deleteUpgradePlan = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/delete',
    method: 'POST',
    data,
  });
};

/**
 * @desc 升年级方式
 */
export const getUpgradePlanWays = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/listWaysWithGrade',
    method: 'GET',
    data,
  });
};

/**
 * @desc 年级列表
 */
export const getBasicSchGradeList = (data) => {
  return request({
    url: '/serv-manager/basicSchGrade/selectUpgradeList',
    method: 'GET',
    data,
  });
};

/**
 * @desc 生成数据
 * @param {number} upgradePlanId  计划id
 */
export const genData = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/genData',
    method: 'POST',
    data,
  });
};

/**
 * @desc 保存升年级计划
 * @param {number} upgradePlanId  计划id
 * @param {string} newSchYearName  新学年名称
 * @param {boolean} isRightAway  是否立即生效
 * @param {string} upgradeTime  升年级时间，当isRightAway为true时无效
 * @param {Array} upgradePlanObjs  升年级方式和对象
 * upgradePlanObjs :{
 *  upgradePlanId, 升学年计划id
 *  gradeId, 年级id
 * gradeName, 年级名称
 * upgradeWay, 升年级方式
 * upgradeWayName 升年级方式名称
 * }

 */
export const saveUpgradePlan = (data) => {
  return requestByJsonType({
    url: '/serv-manager/biz/upgradePlan/saveOrUpdate',
    method: 'POST',
    data,
  });
};

/**
 * @desc 升年级进度
 * @param {string} taskCode upgradeGenData：生成数据 upgradeDoUpgrade：升年级
 * @param {string} taskKey 生成数据时传入升年级计划id
 */
export const getUpgradePlanProgress = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/getProgress',
    method: 'Get',
    data,
  });
};

/**
 * @desc 升年级计划详情
 * @param {Number} upgradePlanId
 */
export const getUpgradePlanDetail = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/detail',
    method: 'Get',
    data,
  });
};

/**
 * @desc 升年级时间延期
 * @param {Number} upgradePlanId // 计划id
 * @param {Number} hour // 延期的小时数
 */
export const delayUpgrade = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/delayUpgrade',
    method: 'POST',
    data,
  });
};

/**
 * @desc 确认升年级
 * @param {Number} upgradePlanId // 计划id
 */
export const doUpgrade = (data) => {
  return request({
    url: '/serv-manager/biz/upgradePlan/doUpgrade',
    method: 'POST',
    data,
  });
};

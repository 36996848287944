import Vue from 'vue';

import '@/styles/style.scss'; // global css
import '@/assets/iconfont/iconfont.js';

import App from './App.vue';
import router from './router';
import store from './store';

import './extension'; // vue extension
import './assets/tailwind.css';
import './permission'; // permission control
import PubSub from 'pubsub-js';
import './utils/polyfill';

Vue.config.productionTip = false;
// Vue.prototype.$ELEMENT = { size: 'small' };

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

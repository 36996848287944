<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Upgrade from '@/views/back-stage/components/upgrade/index';
export default {
  name: 'App',
  watch: {
    $route(route) {
      const avoidPathList = ['/login', '/sync-redirect', '/sync-wechat'];
      if (!avoidPathList.includes(route.path)) {
        setTimeout(() => {
          Upgrade.check('upgradeDoUpgrade');
        }, 1000);
      } else {
        Upgrade.destroy();
      }
    },
  },
};
</script>

import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.permissionId to determine if the current user has permission
 * @param permissionIds
 * @param route
 */
function hasPermission(permissionCodes, route) {
  if (route.meta?.permissionCode) {
    return permissionCodes.includes(route.meta.permissionCode);
  } else {
    return true;
  }
}

/**
 * Set back-stage default redirect
 * @param routes asyncRoutes
 */
function setDefaultRoute(routes) {
  routes.forEach((route) => {
    if (route.path === '/back-stage' && route?.children.length > 0) {
      route.redirect = `/back-stage/${route.children[0].path}`;
      return;
    }
  });
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param permissionIds
 */
export function filterAsyncRoutes(routes, permissionCodes) {
  const res = [];

  routes.forEach((route) => {
    if (!route) {
      return;
    }

    const tmp = { ...route };
    if (hasPermission(permissionCodes, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissionCodes);
      }
      (route.path !== '/back-stage' || tmp.children?.length) && res.push(tmp);
    }
  });

  return res;
}

const state = {
  routes: [],
  addRoutes: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
};

const actions = {
  generateRoutes({ commit }, permissions) {
    return new Promise((resolve) => {
      let accessedRoutes;
      let permissionCodes = permissions.map((x) => x.code);

      accessedRoutes = filterAsyncRoutes(asyncRoutes, permissionCodes);
      setDefaultRoute(accessedRoutes);

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

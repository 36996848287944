<template>
  <el-dialog
    class="tipDialog"
    custom-class="ql-dialog"
    :visible.sync="visible"
    :title="title"
    width="500px"
    top="240px"
    :before-close="handleBeforeClose"
  >
    <svg class="icon tipDialog_icon" aria-hidden="true">
      <use :xlink:href="dialogIcon"></use>
    </svg>
    <div class="tipDialog_content">
      <slot></slot>
    </div>
    <el-button @click="handleConfirm" type="primary">{{ confirmText }}</el-button>
  </el-dialog>
</template>

<script>
import { Dialog, Button } from 'element-ui';
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '提示',
    },
    confirmText: {
      type: String,
      default: '确定',
    },
    type: {
      type: String,
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    dialogIcon() {
      if (this.type === 'warning') {
        return '#icon-icon_jinggao';
      }
      return '#icon-icon_chenggong';
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },

    handleBeforeClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.tipDialog {
  &::v-deep {
    .el-dialog__body {
      text-align: center;
    }
  }

  .tipDialog_content {
    line-height: 24px;
    padding: 16px 0 48px;
  }

  .tipDialog_icon {
    width: 64px;
    height: 64px;
  }

  .el-button {
    width: auto;
  }
}
</style>

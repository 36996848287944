// import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

import router from './router';
import store from './store';

import { getToken } from '@/utils/cookies'; // get token from cookie
import { checkToken } from '@/utils/common';
import { getRouteByPermission } from '@/utils/permission';
import { syncLogout } from '@/utils/sync'; // third project function

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const loginPath = '/login';
// const homePath = '/home';
const redirectPath = '/sync-redirect';
const wechatPath = '/sync-wechat';
const whiteList = [loginPath, redirectPath, wechatPath]; // no redirect whitelist
const defaultTitle = '后台管理'; // default title

router.beforeEach(async (to, from, next) => {
  // change page title
  if (!store.state.common.platformName) {
    await store.dispatch('common/getPlatformName');
  }
  document.title = to.meta.requiresTitle ? to.meta.title || store.state.common.platformName : defaultTitle;

  // start progress bar
  NProgress.start();

  // determine whether the user has logged in
  checkToken(to);
  const hasToken = getToken();

  if (hasToken) {
    // 有token
    if (whiteList.includes(to.path)) {
      // 登录状态下，跳转登录页默认重定向到云平台 home
      if (to.path === loginPath) {
        next(redirectPath + '?isLogin=true');
      } else if (to.path === wechatPath) {
        // 微信扫码登录需要优先获取userId
        try {
          store.commit('user/SET_TOKEN', hasToken);
          await store.dispatch('user/getUserInfo');
          next();
        } catch (err) {
          console.error(err);
          next(loginPath);
        }
      } else {
        next();
      }

      NProgress.done();
    } else {
      const hasPermissions = store.getters.permissions?.length > 0;
      const userStatus = store.getters.userStatus;

      try {
        // 根据 userInfo 加载动态路由
        if (!userStatus) {
          await store.dispatch('user/getUserInfo');
          // await store.dispatch('user/getBindTencentUser');
          // store.dispatch('common/getLessons');
        }

        // 不用权限的直接进
        if (hasPermissions || !['back-stage', 'data-prepare'].some((key) => to.path.includes(key))) {
          next();
        } else {
          await getRouteByPermission();

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        }
      } catch (error) {
        // 重置token并跳转到登录页
        await store.dispatch('user/resetToken');

        // next(`${loginPath}?redirect=${to.path}`);
        next(loginPath);
        NProgress.done();
      }
    }
  } else {
    // 没有token
    if (whiteList.includes(to.path)) {
      if (to.path === loginPath && localStorage.getItem('syncToken')) {
        // 退出登录时，同步登出第三方平台
        syncLogout();
      } else {
        // 白名单内，直接跳转
        next();
      }
    } else {
      // next(`${loginPath}?redirect=${to.path}`);
      next(loginPath);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

router.onError((error) => {
  console.log(`error:${error}`);
  console.dir(router);
  const pattern = /Loading chunk .* failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

/**
 * 离线升级
 */

import { getHostUrl } from '@/utils/common';
import { checkUpgradeResult, getUpgradeTaskStatus, getRestartStatus } from '@/api/basic';

const isTrueSet = (val) => {
  return val === 'true';
};
const hostUrl = getHostUrl();

// 离线升级状态，与dialog界面显示对应
export const VIEW_STATUS = {
  NOT_START: undefined, // 未开启
  LOAGING: 'loading', // 加载中
  FAIL: 'fail', // 失败
};

const state = {
  upgradeViewStatus: VIEW_STATUS.NOT_START, // 离线升级状态，与dialog界面显示对应
  upgradeCode: undefined, // 接口返回的状态code
  isUpgrade: isTrueSet(localStorage.getItem('isUpgrade')) || false, // 是否处于离线升级中
  isRestart: isTrueSet(localStorage.getItem('isRestart')) || false, // 是否处于重启中
  showUpgradeStatus: localStorage.getItem('showUpgradeStatus') || false, // (login view)是否显示升级结果（一次性的，即抛
  isCheckUpgradeResult: isTrueSet(localStorage.getItem('isCheckUpgradeResult')) || false, // (back-stage view)是否显示升级失败信息（一次性的，即抛
};

const mutations = {
  SET_UPGRADE_STATUS: (state, value) => {
    state.upgradeViewStatus = value;
  },
  SET_UPGRADE_CODE: (state, value) => {
    state.upgradeCode = value;
  },
  SET_IS_UPGRADE: (state, bool) => {
    state.isUpgrade = bool || false;
    bool === '' ? localStorage.removeItem('isUpgrade') : localStorage.setItem('isUpgrade', bool);
  },
  SET_IS_RESTART: (state, bool) => {
    state.isRestart = bool || false;
    bool === '' ? localStorage.removeItem('isRestart') : localStorage.setItem('isRestart', bool);
  },
  SET_SHOW_UPGRADE_STATUS: (state, value) => {
    state.showUpgradeStatus = value || false;
    value === '' ? localStorage.removeItem('showUpgradeStatus') : localStorage.setItem('showUpgradeStatus', value);
  },
  SET_CHECK_UPGRADE_RESULT: (state, bool) => {
    state.isCheckUpgradeResult = bool || false;
    bool === '' ? localStorage.removeItem('isCheckUpgradeResult') : localStorage.setItem('isCheckUpgradeResult', bool);
  },
};

const actions = {
  // 获取离线升级任务状态
  // 启动了离线升级任务，查询离线升级过程中的任务状态
  getUpgradeStatus({ commit }) {
    return getUpgradeTaskStatus(hostUrl).then(({ status, code }) => {
      if (status !== 0) {
        return;
      }
      commit('SET_UPGRADE_CODE', code);

      switch (code) {
        case 1:
        case 2:
        case 4:
        case 6:
        case 7:
        case 10:
          // 加载中
          commit('SET_UPGRADE_STATUS', VIEW_STATUS.LOAGING);
          commit('SET_IS_UPGRADE', true); // 升级中
          break;
        case 8:
          // 升级成功 重启
          commit('SET_UPGRADE_STATUS', VIEW_STATUS.LOAGING);
          commit('SET_IS_UPGRADE', ''); // clear
          commit('SET_IS_RESTART', true); // 重启中
          break;
        case 3:
        case 5:
        case 9:
          // 升级失败
          commit('SET_UPGRADE_STATUS', VIEW_STATUS.FAIL);
          commit('SET_IS_UPGRADE', ''); // clear
          break;
        case 11:
          // 升级失败,还原完成 重启
          commit('SET_UPGRADE_STATUS', VIEW_STATUS.LOAGING);
          commit('SET_IS_UPGRADE', ''); // clear
          commit('SET_IS_RESTART', true); // 重启中
          break;
        default:
          commit('SET_UPGRADE_STATUS', VIEW_STATUS.NOT_START);
          break;
      }

      return code;
    });
  },
  // 获取重启状态
  // 升级成功或升级失败还原，查询重启的状态
  getRestartStatus() {
    return getRestartStatus();
  },
  // 查询升级状态
  // 当上一次升级结束后，重新登录进入后台管理，调用此接口，查看升级结果
  checkUpgradeResult({ commit }) {
    return checkUpgradeResult(hostUrl)
      .then(({ status, code }) => {
        if (status !== 0) {
          return;
        }
        commit('SET_UPGRADE_CODE', code);

        return new Promise((resolve, reject) => {
          if ([9, 11].includes(code)) {
            commit('SET_UPGRADE_STATUS', VIEW_STATUS.FAIL);
            resolve();
          } else {
            reject();
          }
        });
      })
      .finally(() => {
        commit('SET_CHECK_UPGRADE_RESULT', '');
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

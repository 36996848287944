export default {
  name: 'Button',

  props: {
    /**
     *   ○ 填充类型
     *     ■ btn-fill-primary：      主题色底 + 白色字
     *   ○ 镂空类型
     *     ■ btn-ghost-primary：     主题色边框 + 主题色字 + 白底
     *     ■ btn-ghost-secondary：   灰色边框 + 黑色字 + 白底
     *     ■ btn-ghost-danger：      红色边框 + 红色字 + 白底
     * */
    type: {
      type: String,
      default: 'btn-ghost-secondary',
    },

    /**
     *   ○ small： _*24
     *   ○ normal： _*32 （默认）
     *   ○ medium： _*40
     *   ○ large： _*48
     * */
    size: {
      type: String,
      default: 'normal',
    },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      kflag: false,
    };
  },
};

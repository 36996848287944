import Vue from 'vue';
import main from './bindPhone';
import { getLoginDispatch } from '@/api/common';

let BindPhoneConstructor = Vue.extend(main);

let instance = null;

function initInstance() {
  instance = new BindPhoneConstructor({
    el: document.createElement('div'),
  });
  document.body.appendChild(instance.$el);
}

const BindPhone = () => {};
/**
 * @desc 判断是否需要绑定手机
 * @example this.$tlg.check();
 */
BindPhone.check = () => {
  getLoginDispatch().then((res) => {
    const { mobileBinding, mobileNo } = res.result;
    //没有开启手机验证功能
    if (
      !Object.prototype.hasOwnProperty.call(res.result, 'mobileBinding') &&
      !Object.prototype.hasOwnProperty.call(res.result, 'mobileNo')
    )
      return;

    // 1、mobileBinding为true, 不弹窗
    // 2、mobileBinding为false, molibeNo为空 ==> 绑定手机弹窗
    // 3、mobileBinding为false, molibeNo不为空 ==> 更改手机弹窗
    if (!mobileBinding) {
      initInstance();
      instance.visible = true;
      if (!mobileNo) {
        instance.form.phone = mobileNo || '';
        instance.isFirstBindPhone = true;
      } else {
        instance.form.oldPhone = mobileNo || '';
      }
    }
  });
};
/**
 * @desc 销毁绑定手机弹窗
 * @example this.$tlg.destroy();
 */
BindPhone.destroy = () => {
  instance && (instance.visible = false);
};

export default BindPhone;

<template>
  <div>
    <el-dialog v-if="beforeUpgrade" visible :show-close="false" width="450px" top="35vh">
      <div class="flex items-center">
        <i class="el-icon-warning text-A5 text-25"></i>
        <div class="ml-17px text-F1 text-18">确认后立即生效，不可撤销!</div>
      </div>
      <p class="ml-44px mt-10px text-F2 text-16">即将到达升年级时间{{ upgradePlan.upgradeTime }}，到点后开始升年级。</p>
      <span slot="footer" class="dialog-footer">
        <EButton @click="delay">延时一小时</EButton>
        <EButton type="btn-fill-primary" style="background: #f26363; border: #f26363" @click="upgradeNow">确定</EButton>
      </span>
    </el-dialog>
    <el-dialog v-if="upgrading" visible :show-close="false" width="400px" top="35vh">
      <div class="">
        <el-progress
          :percentage="progress"
          :stroke-width="8"
          :text-color="progress === 100 ? '#38C3A1' : ''"
        ></el-progress>
        <p class="mt-24px text-center">{{ progressTips }}</p>
      </div>
    </el-dialog>
    <el-dialog v-if="upgradeSuccessed" visible :show-close="false" width="550px" top="35vh">
      <div class="flex items-center">
        <i class="el-icon-warning text-A5 text-25"></i>
        <div class="ml-17px text-F1 text-18">
          系统已在{{ upgradePlan.finishTime }}完成升年级操作，当前学年为【{{ upgradePlan.newSchYearName }}】
        </div>
      </div>
      <p class="ml-44px mt-10px text-F2 text-16">
        请尽快在【后台管理】模块确认升年级后的【班级】、【任教】、【点阵笔】是否无误，并补充【学期】信息
      </p>
      <span slot="footer" class="dialog-footer">
        <EButton type="btn-fill-primary" @click="closeDialog('success')">去确定</EButton>
      </span>
    </el-dialog>
    <el-dialog v-if="upgradeFailed" visible :show-close="false" width="550px" top="35vh">
      <div class="flex items-center">
        <i class="el-icon-warning text-A5 text-25"></i>
        <div class="ml-17px text-F1 text-18">{{ errorTips }}</div>
      </div>
      <p class="ml-44px mt-10px text-F2 text-16">如需修改升年级计划请在[后台管理-基础数据-升年级]模块修改</p>
      <span slot="footer" class="dialog-footer">
        <EButton type="btn-fill-primary" @click="closeDialog('failed')">确定</EButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Progress, Dialog } from 'element-ui';
import { delayUpgrade, doUpgrade } from '@/api/basic';
import EButton from '@/components/button/index.vue';
import Upgrade from '@/views/back-stage/components/upgrade';
export default {
  name: 'Upgrade',
  components: {
    [Dialog.name]: Dialog,
    [Progress.name]: Progress,
    EButton,
  },
  data() {
    return {
      progress: 0,
      progressTips: '正在对班级进行升年级',
      errorTips: '系统升年级失败,请重新设置升年级时间',
      status: null,
      timer: null,
      upgradePlan: {},
    };
  },
  computed: {
    beforeUpgrade() {
      return this.status === 0;
    },
    upgrading() {
      return this.status === 1;
    },
    upgradeSuccessed() {
      return this.status === 2;
    },
    upgradeFailed() {
      return this.status === -1;
    },
  },
  methods: {
    closeDialog(type) {
      this.status = null;
      let routeName = '';
      if (type === 'success') {
        routeName = 'basic-class';
      } else {
        routeName = 'basic-upgrade';
      }
      if (this.$route.name === 'upgrade-list') {
        PubSub.publish('updateList');
      } else {
        this.$router.push({
          name: routeName,
        });
      }
    },
    delay() {
      delayUpgrade({
        upgradePlanId: this.upgradePlan.id,
        hour: 1,
      }).finally(() => {
        this.status = null;
        Upgrade.queryProgress('upgradeDoUpgrade');
      });
    },
    upgradeNow() {
      doUpgrade({
        upgradePlanId: this.upgradePlan.id,
      }).finally(() => {
        this.status = null;
        Upgrade.queryProgress('upgradeDoUpgrade');
      });
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>

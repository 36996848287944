export const dataPrepareRoute = {
  path: '/data-prepare',
  redirect: '/data-prepare/class',
  component: () => import('@/views/data-prepare/index.vue'),
  meta: {
    permissionCode: '',
    title: '数据准备',
    icon: 'icon-icon_cebianlan_jichushuju',
  },
  children: [
    {
      path: 'class',
      name: 'data-prepare-class',
      component: () => import('@/views/data-prepare/subPages/class/index.vue'),
      meta: {
        title: '班级',
      },
    },
    {
      path: 'teach',
      name: 'data-prepare-teach',
      component: () => import('@/views/data-prepare/subPages/teach/index.vue'),
      meta: {
        title: '任教',
      },
    },
    {
      path: 'pen',
      name: 'data-prepare-pen',
      component: () => import('@/views/data-prepare/subPages/pen/index.vue'),
      meta: {
        title: '点阵笔',
      },
    },
  ],
};

export default dataPrepareRoute;

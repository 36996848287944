<template>
  <el-dialog :visible.sync="visible" :custom-class="`custom-toast-class toast-${type}`" top="30vh" :width="width">
    <i
      class="iconfont toast_icon"
      :class="type === 'success' ? 'icon-icon_chenggong text-C1' : 'icon-icon_jinggao text-A5'"
    ></i>
    <div class="toast_title T4B">
      <slot name="title">{{ title }}</slot>
    </div>
    <div class="T5-2">
      <slot>{{ content }}</slot>
    </div>
    <EButton class="toast-confirm" type="btn-fill-primary" v-if="confirmText" @click="handleConfirm">{{
      confirmText
    }}</EButton>
    <p class="T5-4 mg-t-18 cursor-pointer toast-cancel" v-if="cancelText" @click="handleCancel">{{ cancelText }}</p>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import EButton from '@components/button/index.vue';
export default {
  components: {
    [Dialog.name]: Dialog,
    EButton,
  },
  data() {
    return {
      visible: false,
      type: 'success', // success warning
      title: '',
      content: '',
      width: '300px',
      duration: 0,
      confirmText: '',
      cancelText: '',
      confirmCallback: undefined,
      cancelCallback: undefined,

      timer: null,
    };
  },
  methods: {
    init() {
      this.type = 'success';
      this.title = '';
      this.content = '';
      this.width = '300px';
      this.duration = 0;
      this.confirmText = '';
      this.cancelText = '';
      this.confirmCallback = undefined;
      this.cancelCallback = undefined;
    },

    open() {
      this.visible = true;
      if (this.duration) {
        this.createTimer();
        setTimeout(this.handleConfirm, this.duration);
      }
    },

    close() {
      clearInterval(this.timer);
      this.visible = false;
    },

    // 倒计时，若content包含${second}，直接在此位置插入倒计时秒数
    createTimer() {
      if (this.content.includes('${second}')) {
        let content = this.content;
        let second = this.duration / 1000;
        this.content = content.replace('${second}', second);
        this.timer = setInterval(() => {
          second--;
          this.content = content.replace('${second}', second);
        }, 1000);
      }
    },

    handleConfirm() {
      if (this.confirmCallback) {
        this.confirmCallback();
      }
      this.close();
    },

    handleCancel() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      this.close();
    },
  },
};
</script>

<style lang="scss">
.custom-toast-class {
  min-height: 186px;
  padding: 0;
  border-radius: 24px;
  overflow: hidden;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 32px;
    text-align: center;
  }

  .toast_icon {
    display: inline-block;
    font-size: 52px;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .toast_title {
    margin-bottom: 4px;
  }

  .toast-confirm {
    margin-top: 32px;
  }

  .toast-cancel {
    margin-top: 16px;
    margin-bottom: -8px;
  }
}
</style>

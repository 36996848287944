<template>
  <el-button class="verification-code" @click="sendCode" :disabled="disabled" :loading="loading">{{
    btnText
  }}</el-button>
</template>

<script>
import { Button } from 'element-ui';
import { sendCaptcha } from '@/api/user';

export default {
  name: 'verificationCode',
  model: {
    prop: 'phone',
    event: 'change',
  },
  props: {
    phone: {
      type: [String, Number],
      default: '',
      required: true,
    },
    type: {
      type: [String, Number],
      default: '1',
    },
  },
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      btnText: '获取验证码',
      loading: false,
      timer: null,
      disabled: false,
    };
  },
  methods: {
    sendCode() {
      // 60秒倒计时，防止频繁点击
      function countDown(vm, count) {
        vm.disabled = true;
        vm.btnText = `已发送(${count}s)`;
        if (count > 0) {
          vm.timer = setTimeout(() => {
            countDown(vm, count - 1);
          }, 1000);
        } else {
          vm.btnText = '重新获取';
          vm.disabled = false;
          clearTimeout(vm.timer);
          vm.timer = null;
        }
      }
      this.$parent.elForm.validateField('phone', (err) => {
        if (!err) {
          this.loading = true;
          sendCaptcha({ mobile: this.phone, type: this.type })
            .then(() => {
              let count = 60;
              countDown(this, count);
            })
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>

import Vue from 'vue';
import axios from 'axios';
import content from './content.vue';
import { downloadFileByPost, downloadFileByGet } from '@/utils/common';

const TYPE_MAP = {
  teacher: '/serv-manager/teacher/export',
  teach: '/serv-manager/basicClassRTeacher/export',
  class: '/chonggou/exportClass.do',
  classUser: '/serv-manager/basicClassRStu/exportClassUser',
  student: '/serv-manager/student/export',
  pen: '/serv-devicemanagement/pubDeviceDzb/exportDzbExcel',
  subject: '/chonggou/exportLesson.do',
  homework: '/serv-manager/export/exportUserPrintBook',
};

const CANCEL_TEXT = '取消导出操作';

const batchExport = {
  data() {
    return {
      isExport: false,
      type: '',
      data: {},
      axiosSource: null,
      msgInstance: null,
      isCloseByCode: false, // 是否自动关闭

      downloadMethod: 'POST',
    };
  },
  methods: {
    export() {
      if (this.isExport) {
        this.$message.warning('正在执行导出操作，请稍后重试');
        return;
      }
      this.isExport = true;

      // 导出数据
      this.exportData();

      // message 提示
      const h = this.$createElement;
      this.msgInstance = this.$message({
        message: h(content, null),
        customClass: 'batchExport-message',
        duration: 0,
        showClose: true,
        onClose: () => {
          if (!this.isCloseByCode) {
            this.isExport = false;
            this.exportInterrupt();
          }
          this.isCloseByCode = false;
        },
      });
    },

    exportData() {
      this.axiosSource = axios.CancelToken.source(); // axios cancel标识

      (this.downloadMethod === 'POST' ? downloadFileByPost : downloadFileByGet)(
        TYPE_MAP[this.type],
        this.data,
        this.axiosSource.token
      )
        .then(() => {
          this.isCloseByCode = true;
          // 延长500ms关闭
          setTimeout(() => {
            this.msgInstance.close();
            this.isExport = false;
          }, 500);
        })
        .catch((error) => {
          if (error && error.message !== CANCEL_TEXT) {
            this.isCloseByCode = true;
            this.$message.error(error);
            this.msgInstance.close();
          }
          this.isExport = false;
        });
    },

    exportInterrupt() {
      // 中断上传文件到服务器
      if (this.axiosSource) {
        this.axiosSource.cancel(CANCEL_TEXT);
        this.axiosSource = null;
      }
    },
  },
};

let BatchExportConstructor = Vue.extend(batchExport);

let instance = null;

function initInstance() {
  instance = new BatchExportConstructor();
  // document.body.appendChild(instance.$el);
}

const BatchExport = function (options = {}) {
  if (!instance) {
    initInstance();
  }

  instance.type = options.type;
  instance.data = options.data || {};
  instance.downloadMethod = options.downloadMethod || 'POST';

  Vue.nextTick(() => {
    instance.export();
  });
};

export default BatchExport;

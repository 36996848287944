export const authorityManageRoute = {
  path: 'authority-manage',
  name: 'authority-manage',
  component: () => import('@/views/authority-manage/index.vue'),
  meta: {
    permissionCode: 'authority-manage',
    title: '业务管理',
    icon: 'icon-icon_cebianlan_disanfangguanli',
  },
  children: [
    // {
    //   path: 'cross-course',
    //   name: 'cross-course',
    //   component: () => import('@/views/authority-manage/subPages/cross-course/index.vue'),
    //   meta: {
    //     title: '专递课堂',
    //   },
    // },
    {
      path: 'homework-book-binding',
      name: 'homework-book-binding',
      component: () => import('@/views/authority-manage/subPages/homework-book-binding/index.vue'),
      meta: {
        title: '作业本绑定',
      },
    },
    {
      path: 'custom-config',
      name: 'custom-config',
      component: () => import('@/views/authority-manage/subPages/custom-config/index.vue'),
      meta: {
        title: '个性化配置',
      },
    },
  ],
};

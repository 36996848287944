/**
 * @file 用户信息
 */
import request from '@/utils/request';

/** 个人中心 */

/**
 * @desc 修改密码
 * @param {String} oldPassword 旧密码
 * @param {String} password 新密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 * @param {String} confirmPassword 确认密码, 8-24位，至少包含数字、字母或特殊字符中两种方式
 */
export const resetPassword = (data) => {
  return request({
    url: '/app/user/passsword/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 手机绑定 获取手机号
 */
export const getUserMobile = (data) => {
  return request({
    url: '/user/json/setMobile.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 手机绑定 发送短信获取验证码
 * @param {String} mobile 新手机号码
 * @param {String} type 短信类型，1：修改/绑定手机号码
 */
export const sendCaptcha = (data, serverUrl = '') => {
  return request({
    url: `${serverUrl}/app/captcha/captcha.do`,
    method: 'post',
    data,
  });
};
/**
 * @desc 手机绑定 保存绑定手机号码
 * @param {String} mobile 新手机号码
 * @param {String} code 验证码
 */
export const saveUserMobile = (data) => {
  return request({
    url: '/saveMobileBinding.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 管理员手机绑定，不需验证码
 * @param {String} mobile 新手机号码
 */
export const saveUserMobileMark = (data) => {
  return request({
    url: '/saveMobileBindingOnlyMobile.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 管理员手机验证码校验
 * @param {String} mobile 手机号码
 * @param {String} code 验证码
 */
export const verifyUserCode = (data) => {
  return request({
    url: '/verifySmsCode.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 关联管理 获取关联状态
 */
export const getOAuth2bindingInfo = (data) => {
  return request({
    url: '/OAuth2/country/bindingInfo.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 关联管理 解除关联状态
 */
export const unbindOAuth2 = (data) => {
  return request({
    url: '/OAuth2/country/unbind.do',
    method: 'post',
    data,
  });
};
/**
 * @desc //学校学科列表查询
 */
export const getLessonAllList = (data) => {
  return request({
    url: '/json/lessonAllList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取用户任教信息
 */
export const getLessonList = (data) => {
  return request({
    url: '/wktClass/teacherLessons.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 腾讯智慧校园绑定 获取绑定信息
 */
export const getBindTencent = (data) => {
  return request({
    url: '/txCampus/bindInfo.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 腾讯智慧校园绑定 解除绑定
 */
export const removeBindTencent = (data) => {
  return request({
    url: '/txCampus/remove.do',
    method: 'post',
    data,
  });
};

/**
 * @desc 公共服务 微信绑定用户
 * @param {String} code 微信回调过来的code，云层重定向到校层时带过来，用于获取用户信息 必传
 * @param {Number} schoolId 学校id 必传
 * @param {String} userName 用户名（同登录用户名）
 * @param {String} password 密码（rsa加密后）
 * @param {Number} deviceType 设备类型(1: Android; 2: iOS; 3: PC; 4: PAD 5:小程序)
 * @param {Number} productType 产品类型(1.教学云平台)
 * @param {Number} replaceBind 是否强制更改绑定(1: 是; 2: 否)
 */
export const wechatBindUserServer = (data, needResponse = false) => {
  return request({
    url: '/serv-manager/auth/wechat/wechatBindUser',
    method: 'get',
    data,
    needResponse,
  });
};

/**
 * @desc 公共服务 解除微信绑定
 */
export const wechatUnbindUserServer = (data) => {
  return request({
    url: '/serv-manager/auth/wechat/wechatUnbindUser',
    method: 'get',
    data,
  });
};

/**
 * @desc 公共服务 个人中心 用户信息
 */
export const getUserInfoForPersonalCenter = (data) => {
  return request({
    url: '/serv-manager/user/selectUserInfoForPersonalCenter',
    method: 'post',
    data,
  });
};

/**
 * @desc 公共服务 个人中心 修改密码
 * @param {String} oldPwd 旧密码 必须是rsa加密后字符串 必传
 * @param {String} newPwd 新密码 必须是rsa加密后字符串 必传
 * @param {String} confirmNewPwd 确认密码  必须是rsa加密后字符串 必传
 */
export const modifyPwdServer = (data) => {
  return request({
    url: '/serv-manager/auth/modifyPwd',
    method: 'post',
    data,
  });
};

/**
 * @desc 公共服务 个人中心 关联或更改手机号
 * @param {String} moblieNo 手机号 必传
 * @param {String} code 验证码 必传
 */
export const updateMobileServer = (data) => {
  return request({
    url: '/serv-manager/user/updateMobileNo',
    method: 'post',
    data,
  });
};

/**
 * @desc 公共服务 个人中心 修改头像
 * @param {String} iconUrl 头像地址 必传
 */
export const updateAvatarServer = (data) => {
  return request({
    url: '/serv-manager/user/updateIcon',
    method: 'post',
    data,
  });
};

/**
 * @desc 公共服务 重置密码
 * @param {Number} schoolId 学校id 必传
 * @param {String} mobile 手机号 必传
 * @param {String} code 验证码 必传
 * @param {String} newPwd 新密码 必传
 */
export const resetPwdServer = (data) => {
  return request({
    url: '/serv-manager/auth/resetPwd',
    method: 'post',
    data,
  });
};

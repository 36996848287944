<template>
  <div class="batchExport_content">
    <span class="batchExport_icon"
      ><svg viewBox="25 25 50 50" class="circular">
        <circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg
    ></span>
    <p class="batchExport_text">正在导出，请稍等…</p>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.batchExport_content {
  display: flex;
  align-items: center;

  .batchExport_icon {
    margin-right: 12px;
  }

  .batchExport_text {
    font-weight: 400;
    color: #6d7171;
  }
  .circular {
    width: 24px;
    height: 24px;
    animation: loading-rotate 2s linear infinite;

    .path {
      animation: loading-dash 1.5s ease-in-out infinite;
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
      stroke-width: 2;
      stroke: #38c3a1;
      stroke-linecap: round;
    }
  }
}

@keyframes loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
</style>

import { adminRoleIds, schoolAdminRoleId } from '@/utils/permission';

const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  schoolId: (state) => state.user.userInfo.schoolId,
  hostUrl: (state) => state.user.userInfo.hostUrl,
  userStatus: (state) => state.user.userStatus,
  permissions: (state) => state.user.permissions,
  isBindTencent: (state) => !!state.user.bindTencentUsername,
  // 管理员账号
  isAdmin: (state) => {
    let { roleIds } = state.user.userInfo;
    return roleIds && adminRoleIds.some((item) => roleIds.includes(item));
  },
  // 校层管理员
  isSchoolAdmin: (state) => {
    let { roleIds } = state.user.userInfo;
    return roleIds && roleIds.includes(schoolAdminRoleId);
  },
  routes: (state) => state.route.routes,
};

export default getters;

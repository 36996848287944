import Vue from 'vue';
import content from './content.vue';

let ToastConstructor = Vue.extend(content);

let instance = null;

function initInstance() {
  instance = new ToastConstructor({
    el: document.createElement('div'),
  });
  document.body.appendChild(instance.$el);
}

function isVNode(node) {
  return node !== null && typeof node === 'object' && Object.prototype.hasOwnProperty.call(node, 'componentOptions');
}

const Toast = function (options = {}) {
  if (!instance) {
    initInstance();
  }

  instance.init();

  for (let i in options) {
    instance[i] = options[i];
  }

  if (isVNode(instance.title)) {
    instance.$slots.title = [instance.title];
    instance.title = null;
  } else {
    delete instance.$slots.title;
  }

  if (isVNode(instance.content)) {
    instance.$slots.default = [instance.content];
    instance.content = null;
  } else {
    delete instance.$slots.default;
  }

  Vue.nextTick(() => {
    instance.open();
  });
};

export default Toast;

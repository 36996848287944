import Vue from 'vue';
import router from '@/router';
import index from './index.vue';
import { getUpgradePlanProgress } from '@/api/basic';

let UpGradeConstructor = Vue.extend(index);

let instance = null;

function initInstance() {
  instance = new UpGradeConstructor({
    el: document.createElement('div'),
    router,
  });
  instance.$el.classList.add('upgradeDialog');
  document.body.appendChild(instance.$el);
}

const Upgrade = () => {};

Upgrade.queryProgress = async (code, taskKey) => {
  return new Promise((resolve) => {
    async function queryProgress() {
      const rs = await getUpgradePlanProgress({
        taskCode: code,
        taskKey: code === 'upgradeGenData' ? taskKey : null,
      });
      const { msg, status, step, stepCount } = rs.result ?? {};
      if (!status) {
        clearInterval(instance.timer);
        PubSub.publish('updateList');
      } else if (status === 1) {
        instance.progress = Math.round(((step / stepCount) * 100) / 10) * 10;
        instance.progressTips = msg;
        instance.status = 1;
      } else if (status === 2) {
        instance.progress = 100;
        clearInterval(instance.timer);
        setTimeout(() => {
          if (code === 'upgradeGenData') {
            instance.status = null;
            instance.progress = 0;
            instance.$message.success('生成数据成功');
          } else {
            instance.upgradePlan = rs.result.upgradePlan;
            instance.status = 2;
            PubSub.publish('updateList');
          }
          resolve(rs);
        }, 2000);
      } else {
        clearInterval(instance.timer);
        instance.status = -1;
        instance.errorTips = msg;
        PubSub.publish('updateList');
        resolve();
      }
    }
    instance.status = null;
    queryProgress();
    instance.timer = setInterval(queryProgress, 2000);
  });
};

Upgrade.check = async (code, taskKey) => {
  if (!instance) {
    initInstance();
  }
  if (code === 'upgradeDoUpgrade') {
    const rs = await getUpgradePlanProgress({
      taskCode: code,
    });
    if (rs.result) {
      const status = rs.result.status;
      instance.upgradePlan = rs.result.upgradePlan;
      if (status === 0) {
        // 升年级计划未开始
        const data = rs.result;
        const upgradeTimeStamp = new Date(data.upgradePlan.upgradeTime).getTime();
        const currentTimeStamp = new Date().getTime();
        const minuteDifference = (upgradeTimeStamp - currentTimeStamp) / (1000 * 60);
        if (minuteDifference < 0) {
          return;
        } else if (minuteDifference < 3) {
          // 小于三分钟，则弹出提示
          instance.status = status;
        } else {
          // 大于三分钟，则剩余三分钟再重新弹提示
          setTimeout(() => {
            Upgrade.check('upgradeDoUpgrade');
            console.log(`已设置定时器，${minuteDifference - 3}后进行升级}`);
          }, (minuteDifference - 3) * 1000 * 60);
        }
      } else if (status === 1) {
        Upgrade.queryProgress('upgradeDoUpgrade');
      } else {
        instance.status = status;
      }
    }
  } else {
    // 生成数据
    return Upgrade.queryProgress(code, taskKey);
  }
};

Upgrade.destroy = () => {
  if (instance) {
    clearInterval(instance.timer);
    instance.status = null;
  }
};

export default Upgrade;

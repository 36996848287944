<template>
  <el-dialog title="手机绑定" :visible="visible" :show-close="false" custom-class="ql-dialog" width="600px" top="214px">
    <el-form :model="form" :rules="rules" hide-required-asterisk ref="form" label-width="80px">
      <div class="text-tips mb-6">
        <svg class="fz-16 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_jinggao"></use>
        </svg>
        为响应国家三级等保要求，现将管理员登录方式调整为账号密码+短信验证双重验证登录，以确保数据的安全性。为了不影响您的正常登录使用，<span
          class="text-error"
          >请及时更新可用的手机号。</span
        >
      </div>

      <template v-if="isFirstBindPhone">
        <el-form-item prop="phone" label="手机号:" key="newPhone" size="small">
          <el-input v-model.trim="form.phone" />
        </el-form-item>
        <el-form-item prop="code" label="验证码:" key="code" size="small">
          <el-input class="flex-1 mg-r-18" v-model.trim="form.code" />
          <VerificationCode v-model="form.phone" />
        </el-form-item>
      </template>
      <transition-group name="slide-fade">
        <template v-if="!isFirstBindPhone">
          <el-form-item
            prop="oldPhone"
            :label="isEditPhone ? `旧手机号:` : `手机号:`"
            style="margin-bottom: 15px"
            v-if="!isFirstBindPhone"
            key="oldPhone"
            size="small"
          >
            <span>{{ form.oldPhone }}</span>
            <span class="themeColor cur-hand mg-l-26" @click="isEditPhone = !isEditPhone"
              >{{ isEditPhone ? `取消` : '' }}更改</span
            >
          </el-form-item>
          <el-form-item prop="phone" label="新手机号:" key="phone" v-if="isEditPhone" size="small">
            <el-input v-model.trim="form.phone" />
          </el-form-item>
          <el-form-item prop="code" label="验证码:" key="code" v-if="isEditPhone" size="small">
            <el-input class="flex-1 mg-r-18" v-model.trim="form.code" />
            <VerificationCode v-model="form.phone" />
          </el-form-item>
        </template>
      </transition-group>
    </el-form>
    <span slot="footer">
      <el-button :loading="submitLoading" type="primary" @click="save" size="small">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Form, Button, FormItem, Input } from 'element-ui';
import VerificationCode from '@components/verifyCodeButton/index.vue';
import { checkPhone } from '@/utils/validate';
import { saveUserMobile, saveUserMobileMark } from '@/api/user';
export default {
  name: 'bindPhone',
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Button.name]: Button,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    VerificationCode,
  },
  data() {
    // 自定义手机号验证
    const validatePhone = (rule, value, callback) => {
      const checkMsg = checkPhone(value);
      if (value === this.form.oldPhone) {
        callback(new Error('新手机号与旧手机号不得一致'));
      } else if (checkMsg === true) {
        callback();
      } else {
        callback(new Error(checkMsg));
      }
    };
    return {
      visible: false,
      submitLoading: false,
      isFirstBindPhone: false, //是否为首次绑定
      isEditPhone: false, //是否更改手机号
      form: {
        oldPhone: '',
        phone: '',
        code: '',
      },
      rules: {
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        newPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    save() {
      if (!this.isFirstBindPhone && !this.isEditPhone) {
        saveUserMobileMark({
          mobile: this.form.oldPhone,
        }).then(() => {
          this.visible = false;
        });
        return;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          saveUserMobile({
            mobile: this.form.phone,
            code: this.form.code,
          })
            .then(() => {
              this.$message.success('绑定成功');
              this.visible = false;
            })
            .finally(() => (this.submitLoading = false));
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped src="./index.scss"></style>

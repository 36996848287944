/**
 * 安徽省平台数据同步
 */

import { getTaskByTaskId, getNewestTaskBySchoolKey } from '@/api/basic';

const state = {
  syncTaskStatus: null,
  syncTaskId: localStorage.getItem('syncTaskId') || null,
};

const mutations = {
  SET_SYNC_TASK_STATUS: (state, value) => {
    state.syncTaskStatus = value;
  },
  SET_SYNC_TASK_ID: (state, value) => {
    state.syncTaskId = value || null;
    value === '' ? localStorage.removeItem('syncTaskId') : localStorage.setItem('syncTaskId', value);
  },
};

const actions = {
  // 查询最近一次同步任务
  getNewestTask({ commit, rootState }) {
    return getNewestTaskBySchoolKey({
      schoolKey: rootState.user.userInfo.schoolKey,
    }).then(({ result }) => {
      commit('SET_SYNC_TASK_ID', result[0].id);
      commit('SET_SYNC_TASK_STATUS', result[0].status);

      return result;
    });
  },
  // 根据任务ID查询任务状态
  getSyncStatus({ state, commit }) {
    return getTaskByTaskId({
      taskId: state.syncTaskId,
    }).then(({ result }) => {
      commit('SET_SYNC_TASK_STATUS', result[0]?.status);
      return result;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

// 防抖，避免快速点击，触发多次接口
const debounceClick = {
  inserted: function (el, binding) {
    let timer;
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 500);
    });
  },
};

// 自定义指令
const directives = {
  debounceClick,
};

export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};

/**
 * @file 公共接口
 */
import request from '@/utils/request';

/**
 * @desc 登录之后判断路由跳转
 */
export const getLoginDispatch = (data) => {
  return request({
    url: '/main/jsonDispacth/index.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取用户信息
 */
export const getUserInfo = (data) => {
  return request({
    url: '/user/json/config.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 获取当前登录用户信息
 */
// export const getCurrentLogonInfo = (data) => {
//   return request({
//     url: '/currentLogonInfo.do',
//     method: 'GET',
//     data,
//   });
// };

/**
 * @desc 获取当前登陆账号所有的菜单权限
 */
export const getUserPermission = (data) => {
  return request({
    url: '/json/baseManage.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 获取校层版本信息
 */
export const getLicense = (data) => {
  return request({
    url: '/serv-manager/pub/getVersionInfo',
    method: 'get',
    data,
  });
};

/**
 * @desc 获取云层版本信息
 */
export const getCloudLicense = (data) => {
  return request({
    url: '/serv-qlcloud/pub/getVersionInfo',
    method: 'get',
    data,
  });
};

/** home */
/**
 * @desc 获取用户首页菜单
 */
export const getHomeMenu = (data) => {
  return request({
    url: '/main/jsonData/index.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 系统默认升年级
 * @param { String } schoolId 学校id
 * @param { String } data 升年级配置json，包含年级学段id和是否分班信息（选中班级的升级方式为不分班升级，未选中班级的升级方式为分班升级）
 */
export const postGradeUpgrade = (data) => {
  return request({
    url: '/grade/upgrade.do',
    method: 'post',
    data,
  });
};

/**
 * @desc 导入批量文件
 */
export const importBatchData = (url, data, cancelToken) => {
  return request({
    url,
    method: 'POST',
    cancelToken,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    isUpload: true, // 上传标识
    timeout: 90000,
  });
};

/**
 * @desc 获取异步导入任务的状态
 */
export const getImportAsyncStatus = (data) => {
  return request({
    // url: '/getImportAsyncStatus.do',
    url: '/serv-manager/import/getImportAsyncStatus',
    method: 'GET',
    data,
  });
};

/**
 * @desc 通用POST方法下载
 */
export const downloadByPost = (url, data = {}, cancelToken) => {
  return requestByJsonType({
    url,
    method: 'POST',
    data,
    cancelToken,
    responseType: 'blob',
    needResponse: true,
  });
};

/**
 * @desc 通用GET方法下载
 */
export const downloadByGet = (url, data = {}, cancelToken) => {
  return request({
    url,
    method: 'GET',
    data,
    cancelToken,
    responseType: 'blob',
    needResponse: true,
  });
};

/* ************************** 公共服务 & 基础管理 接口 ************************** */

/**
 * @desc 公共服务 fastdfs-单文件上传
 * @param { Object } file 文件
 */
export const uploadOneFileservice = (data) => {
  return request({
    url: '/fileservice/schoolFile/uploadOne',
    method: 'post',
    data,
  });
};

/**
 * @desc 基础管理 登录
 * @param { String } j_username 账号
 * @param { String } j_password 密码
 * @param { Boolean } isRsaPassword 密码是否加密传入
 * @param { String } deviceType 设备类型 (1: Android; 2: iOS; 3: PC; 4: PAD 5:小程序 ）
 * @param { String } productType 产品类型 1.教学云平台 6.后台管理
 */
export const loginServer = (data) => {
  return request({
    url: '/serv-manager/j_spring_security_check',
    method: 'post',
    data,
    needResponse: true,
  });
};

/**
 * @desc 基础管理 注销
 */
export const logoutServer = (data) => {
  return request({
    url: '/serv-manager/j_spring_security_logout',
    method: 'post',
    data,
  });
};

/**
 * @desc 基础管理 手机验证码登录接口
 * @param {String} schoolId 学校id
 * @param {String} mobile 手机号码
 * @param {String} code 手机验证码
 */
export const mobileLoginServer = (data) => {
  return request({
    url: '/serv-manager/sms/mobileLogin',
    method: 'get',
    data,
    needResponse: true,
  });
};

/**
 * @desc 基础管理 根据token获取用户信息
 */
export const getCurrentLogonInfo = (data) => {
  return request({
    url: '/serv-manager/auth/currentLogonInfo',
    method: 'post',
    data,
  });
};

/**
 * @desc 基础管理 验证手机验证码
 * @param {String} mobile 手机号码
 * @param {String} code 验证码
 */
export const verifyCodeServer = (data) => {
  return request({
    url: '/serv-manager/sms/verifySmsCode',
    method: 'post',
    data,
  });
};

/**
 * @desc 基础管理 获取滑动验证码
 */
export const getSliderCaptcha = (data) => {
  return request({
    url: '/serv-manager/captcha/createSlider',
    method: 'get',
    data,
  });
};

/**
 * @desc contentType json 请求
 */
export const requestByJsonType = ({ url, method, data = {}, ...rest }) => {
  return request({
    url,
    method,
    data,
    contentType: 'application/json',
    ...rest,
  });
};

/**
 * @desc 基础管理 根据学校id查询系统授权状态
 * @param {String} schoolId 学校id
 */
export const getLicenseInfo = (data) => {
  return request({
    url: '/serv-manager/license/getLicenseInfo',
    method: 'get',
    data,
    needResponse: true,
  });
};

/**
 * @desc 基础管理 设置管理员授权信息今日不再提醒状态
 */
export const setLicenseTipsStatus = (data) => {
  return request({
    url: '/serv-manager/license/setManagerLicenseTipsStatus',
    method: 'get',
    data,
  });
};

/* ************************** 云层管理 接口 ************************** */

/**
 * @desc 云层管理 微信登录二维码生成
 * @param {String} schoolId 学校id
 * @param {String} cloudHost 云层重定向的路由地址
 * @param {String} schoolHost 校层重定向的路由地址
 * @param {Number} returnType 返回类型(1、重定向 2、json) 必传
 * @param {String} deviceType 设备类型(1: Android; 2: iOS; 3: PC; 4: PAD 5:小程序) 必传
 * @param {String} productType 产品类型(1.教学云平台) 必传
 * @param {String} token 生成个人中心的微信二维码需要传入
 */
export const getQrConnectCloud = (data) => {
  return request({
    url: '/serv-qlcloud/wechat/genQrConnect',
    method: 'get',
    data,
  });
};

/**
 * @desc 云层管理 根据unionId获取绑定账户的学校列表
 * @param {String} unionId 微信unionId
 */
export const getUserBindSchoolListCloud = (data) => {
  return request({
    url: '/serv-qlcloud/user/getUserBindSchoolListByUnionId',
    method: 'post',
    data,
  });
};

/**
 * @desc 云层管理 微信登录入口
 * @param {String} code 微信回调过来的code，用于获取用户信息 必传
 * @param {String} schoolId 学校id 必传
 * @param {String} schoolHost 校层重定向的路由地址 必传
 * @param {Number} returnType 返回类型(1、重定向 2、json) 必传
 * @param {String} deviceType 设备类型(1: Android; 2: iOS; 3: PC; 4: PAD 5:小程序) 必传
 * @param {String} productType 产品类型(1.教学云平台) 必传
 */
export const loginByWechatValidCloud = (data) => {
  return request({
    url: '/serv-qlcloud/wechat/loginByWechatValid',
    method: 'get',
    data,
    needResponse: true,
  });
};

/**
 * @desc 云层管理 模糊搜索学校
 * @param {String} schoolNameFuzzy 学校名称 必传
 */
export const getSchoolByKeyeordCloud = (data) => {
  return request({
    url: '/serv-qlcloud/org/school/list/name',
    method: 'get',
    data,
  });
};

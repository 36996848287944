import { loginServer, mobileLoginServer, logoutServer, getCurrentLogonInfo } from '@/api/common';
import { getBindTencent } from '@/api/user';
import { getToken, setToken, removeToken, removeAllCookies } from '@/utils/cookies';
import { resetRouter } from '@/router';
import { allPermissions } from '@/utils/permission';

const state = {
  token: getToken(),
  userInfo: {},
  userStatus: false, // 用户信息是否获取
  permissions: [],
  isSchoolSuper: '', // 是否为超级管理员
  importDataShow: '', // 是否显示导入数据按钮
  mainIndexShow: '', // 是否展示首页按钮
  bindTencentUsername: '', // 绑定智慧校园账号
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    token === '' ? removeToken() : setToken(token);
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_ROLE: (state, data) => {
    state.isSchoolSuper = data.isSchoolSuper;
    state.importDataShow = data.importDataShow;
    state.mainIndexShow = data.mainIndexShow;
  },
  SET_USER_STATUS: (state, status) => {
    state.userStatus = status;
  },
  SET_BIND_TENCENT: (state, data) => {
    state.bindTencentUsername = data;
  },
};

const actions = {
  // user login
  login({ commit, dispatch }, { userInfo, formType = 'password', request }) {
    return new Promise((resolve, reject) => {
      const requestFn = request || (formType === 'password' ? loginServer : mobileLoginServer);
      requestFn(userInfo, true)
        .then((response) => {
          let { status, result, msg } = response.data;
          removeAllCookies(); // 避免第三方登录验证后受cookie影响
          if (status === 0) {
            commit('SET_TOKEN', result.token);
            commit('SET_USER_INFO', result.user);
            setToken(result.token);
            resolve();
          } else {
            dispatch('resetToken');
            reject({ status, result, msg });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // weixin login
  wechatLogin({ commit }, data) {
    removeAllCookies(); // 避免第三方登录验证后受cookie影响
    commit('SET_TOKEN', data.token);
    commit('SET_USER_INFO', data.user);
    setToken(data.token);
  },

  // get user info
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrentLogonInfo({})
        .then((response) => {
          const { result } = response;
          if (!result) {
            reject('获取用户信息失败，请重新登录');
          }

          const user = result.user;
          commit('SET_USER_INFO', user);
          commit('SET_USER_STATUS', true);

          resolve({ userInfo: user });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user permission
  getUserPermission({ commit, state }) {
    return new Promise((resolve) => {
      const permissions = [];
      const roleIds = state.userInfo.roleIds;
      for (const key in allPermissions) {
        if (roleIds.includes(allPermissions[key].roleId)) {
          let permission = Object.assign({}, allPermissions[key]);
          permission.code = key;
          permissions.push(permission);
        }
      }

      commit('SET_PERMISSIONS', permissions);
      resolve(permissions);

      // getUserPermission({})
      //   .then((response) => {
      //     const { result } = response;

      //     if (!result) {
      //       reject('获取用户权限信息失败，请重新登录');
      //     }

      //     const { menuList, isSchoolSuper, importDataShow, mainIndexShow } = result;

      //     const permissionsCodes = menuList.map((x) => x.code);

      //     const permissions = [];

      //     for (const key in allPermissions) {
      //       if (permissionsCodes.includes(key)) {
      //         let permission = Object.assign({}, allPermissions[key]);
      //         permission.code = key;
      //         permissions.push(permission);
      //       }
      //     }

      //     commit('SET_PERMISSIONS', permissions);
      //     commit('SET_ROLE', {
      //       isSchoolSuper,
      //       importDataShow,
      //       mainIndexShow,
      //     });

      //     resolve(permissions);
      //   })
      //   .catch((error) => {
      //     reject(error);
      //   });
    });
  },

  // get bind tencent user
  getBindTencentUser({ commit }) {
    return new Promise((resolve, reject) => {
      getBindTencent()
        .then(({ result }) => {
          const bindUserName = result?.bindUserName ?? '';
          commit('SET_BIND_TENCENT', bindUserName);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logoutServer()
        .then(() => {
          commit('SET_TOKEN', '');
          removeAllCookies();
          // commit('SET_PERMISSIONS', []);
          resetRouter();

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '');
      // commit('SET_PERMISSIONS', []);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
